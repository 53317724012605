import React, { useState } from 'react';
import { BsCollectionPlay, BsMailbox, BsMailbox2, BsPencilSquare, BsWhatsapp } from 'react-icons/bs';
import { BiNetworkChart } from 'react-icons/bi';
import InquiryForm from './inquiryForm';
import { WhatsAppImage } from '../assets';

const SmallSideBar = ({ isEmail, isWhatsApp, isQuote, isDemo, isSolutions }) => {
    const [leftNavTab, setLeftNavTab] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const handleCloseForm = () => {
        setShowForm(false);
    };

    const LeftNavToggle = () => {
        if (window.scrollY <= 250) {
            setLeftNavTab(false);
        } else {
            setLeftNavTab(true);
        }
    };

    window.addEventListener('scroll', LeftNavToggle);

    return (
        <>
            <div className={`left-nav active-left-nav desk ${leftNavTab ? 'show' : 'hidden'}`}>
                <div className="container wpb_container">
                    <div className="row d-flex flex-col">
                        {isDemo &&
                            <div className="grid-item col-xl-3 col-lg-6">
                                <a className="rounded-sm component h-100 bgc-white" href="#" target="_self" onClick={() => setShowForm(true)}>
                                    <div className="d-flex align-items-center teal-item vib-content-container">
                                        <BsCollectionPlay />
                                        <p>Request a demo</p>
                                    </div>
                                </a>
                            </div>}
                        {isEmail &&
                            <div className="grid-item col-xl-3 col-lg-6">
                                <a className="rounded-sm component h-100 bgc-white"
                                    href="mailto:sales@radicalminds.in"
                                    target="_self">
                                    <div className="d-flex align-items-center teal-item vib-content-container">
                                        <BsMailbox2 />
                                        <p>Mail Us</p>
                                    </div>
                                </a>
                            </div>}
                        {isWhatsApp &&
                            <div className="grid-item col-xl-3 col-lg-6">
                                <a className="rounded-sm component h-100 bgc-white"
                                    href="https://wa.me/9355321919?text=Hello%2C%20I%20would%20like%20to%20inquire%20about..."
                                    target="_blank" // Opens WhatsApp in a new tab or app
                                    rel="noopener noreferrer">
                                    <div className="d-flex align-items-center teal-item vib-content-container">
                                        <BsWhatsapp />
                                        {/* <FaWhatsapp style={{ marginLeft: '10px', marginRight: '10px' }} />  */}
                                        {/* WhatsApp icon */}
                                        <p>Contact Us</p>
                                    </div>
                                </a>
                            </div>}
                        {isSolutions &&
                            <div className="grid-item col-xl-3 col-lg-6">
                                <a className="rounded-sm component h-100 bgc-white" href="#" target="_self" onClick={() => setShowForm(true)}>
                                    <div className="d-flex align-items-center yellow-item vib-content-container">
                                        <BiNetworkChart />
                                        <p>Design your Solution</p>
                                    </div>
                                </a>
                            </div>}
                        {isQuote &&
                            <div className="grid-item col-xl-3 col-lg-6">
                                <a className="rounded-sm component h-100 bgc-white" href="#" target="_self" onClick={() => setShowForm(true)}>
                                    <div className="d-flex align-items-center red-item vib-content-container">
                                        <BsPencilSquare />
                                        <p>Request a quote</p>
                                    </div>
                                </a>
                            </div>}
                    </div>
                </div>
            </div>
            {showForm && <InquiryForm handleClose={handleCloseForm} />}
        </>
    );
};

export default SmallSideBar;
