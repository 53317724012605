import React, { useState } from 'react';

const TextareaInput = ({ value: initialValue = '', onChange, className = '', placeholder = '', rows = 3,required, label }) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };
  const textareaHeight = `${rows * 1}em`; 
  return (
    <div>

      {label && (
        <label className="form-label">
          {label}
          {required && <span className="text-danger ms-1">*</span>}
        </label>
      )}
      <textarea
        className={`form-control ${className}`}
        style={{ height: textareaHeight }}

        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        rows={rows}
      />
    </div>
  );
};

export default TextareaInput;
