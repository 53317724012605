import React from 'react';
import '../../src/Components/WhatsApp.css'
import { WhatsAppImage } from '../assets';

const WhatsAppIcon = () => {
    return (
        <div className="whatsapp-icon-container">
            <a href="https://wa.me/+919355321919?text=Hii,I contacted through your website." target="_blank" rel="noopener noreferrer">
                <img src={WhatsAppImage} alt="WhatsApp" className="whatsapp-icon" />
            </a>
        </div>
    );
}

export default WhatsAppIcon;
