import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import "../../Components/PolicyPopup/PolicyPopup.css"

function PolicyPopup() {
    const [cookies, setCookie] = useCookies(['policyAccepted']);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if (!cookies.policyAccepted) {
            const timer = setTimeout(() => {
                setShowPopup(true);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [cookies.policyAccepted]);

    const handleAccept = () => {
        // Set the cookie to expire in 365 days
        setCookie('policyAccepted', true, { path: '/', maxAge: 365 * 24 * 60 * 60 });
        setShowPopup(false);
    };

    if (!showPopup) return null;

    return (
        <div className="popup-onload" id="popup">
            <div className="cnt223">
                <h4>This website uses cookies.</h4>
                <p>Cookies are small text files that allow us to create the best browsing experience for you on our site. By continuing to use this website or clicking "Accept &amp; Close", you are agreeing to our use of cookies. To understand how we use cookies or how to manage them, please see our <a href="/cookie-policy" target="_blank" rel="noopener noreferrer">cookies policy.</a></p>
                <div className="cookie-accept">
                    <button id="accept" onClick={handleAccept}>Accept &amp; Close</button>
                </div>
            </div>
        </div>
    );
}

export default PolicyPopup;
