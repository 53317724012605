import React, { useState } from 'react';
import { BsCollectionPlay, BsMailbox2, BsPencilSquare, BsWhatsapp } from "react-icons/bs";
import { TextField } from './InputFeild/InputFeild';
import Modal from "react-bootstrap/Modal";
import { AiOutlineRight } from 'react-icons/ai';
import { BiNetworkChart } from "react-icons/bi";
import InquiryForm from './inquiryForm';


const SmallSideBarMobile = ({ isEmail, isWhatsApp, isQuote, isDemo, isSolutions }) => {
    const [leftNavTab, setLeftNavTab] = useState(false);
    const [leftNavActive, setLeftNavActive] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const handleCloseForm = () => {
        setShowForm(false);
    };

    const LeftNavToggle = () => {
        if (window.scrollY <= 250) {
            setLeftNavTab(false);
        } else {
            setLeftNavTab(true);
        }
    };
    window.addEventListener("scroll", LeftNavToggle);
    const toggleNavLeft = () => setLeftNavActive(!leftNavActive);

    return (
        <>
            <div
                className={`left-nav active-left-nav md ${leftNavTab ? "show" : "hidden"
                    } ${leftNavActive ? "show-nav-mobile" : ""}`}
            >
                <div className="container wpb_container">
                    <div className="show-hide" onClick={toggleNavLeft}>
                        <AiOutlineRight />
                    </div>
                    <div className="row d-flex flex-col">
                        {isDemo &&
                            <div className="grid-item col-xl-3 col-lg-6">
                                <div className="rounded-sm component h-100 bgc-white">
                                    <div
                                        className="d-flex align-items-center teal-item  vib-content-container"
                                        onClick={() => setShowForm(true)}
                                    >
                                        <BsCollectionPlay />
                                        <p>Request a demo</p>
                                    </div>
                                </div>
                            </div>}
                        {isEmail &&
                            <div className="grid-item col-xl-3 col-lg-6">
                                <div className="rounded-sm component h-100 bgc-white">
                                    <a className="rounded-sm component h-100 bgc-white"
                                        href="mailto:sales@radicalminds.in"
                                        target="_self">
                                        <div
                                            className="d-flex align-items-center teal-item  vib-content-container"
                                        >
                                            <BsMailbox2 />
                                            <p>Mail Us</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        }
                        {isWhatsApp &&
                            <div className="grid-item col-xl-3 col-lg-6">
                                <div className="rounded-sm component h-100 bgc-white">
                                    <a className="rounded-sm component h-100 bgc-white"
                                        href="https://wa.me/9355321919?text=Hello%2C%20I%20would%20like%20to%20inquire%20about..."
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <div
                                            className="d-flex align-items-center teal-item  vib-content-container"
                                        >
                                            <BsWhatsapp />
                                            <p>Contact Us</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        }
                        {isSolutions &&
                            <div className="grid-item col-xl-3 col-lg-6">
                                <div className="rounded-sm component h-100 bgc-white">
                                    <div
                                        className="d-flex align-items-center yellow-item vib-content-container"
                                        onClick={() => setShowForm(true)}
                                    >
                                        <BiNetworkChart />
                                        <p>Design your Solution</p>
                                    </div>
                                </div>
                            </div>}
                        {isQuote &&
                            <div className="grid-item col-xl-3 col-lg-6">
                                {/* <a
                        className="rounded-sm component h-100 bgc-white"
                        href="/campaign/get-a-quick-quote-for-genesys-cloud"
                        target="_self"
                    > */}
                                {/* <div
                                    className="d-flex align-items-center red-item vib-content-container"
                                    onClick={() => setShowForm(true)}
                                >
                                    <BsPencilSquare />
                                    <p>Request a quote</p>
                                </div> */}
                                <div className='rounded-sm component h-100 bgc-white'>
                                    <div className="d-flex align-items-center teal-item  vib-content-container"  onClick={() => setShowForm(true)}>
                                        <BsPencilSquare />
                                        <p className='m-0'>Request a quote</p>
                                    </div>
                                </div>
                                {/* </a> */}
                            </div>}
                    </div>

                </div>
            </div>

            {showForm && <InquiryForm handleClose={handleCloseForm} />}
        </>
    );
}

export default SmallSideBarMobile;
