import React from 'react';
import { ThankyouImage } from '../../assets'; 
import './Thankyou.css'; 
import PageLayout from '../../Components/PageLayout';
import { Container } from 'react-bootstrap';

const Thankyou = () => {
    const handleHomeClick = () => {
        window.location.href = '/';
    };

    return (
        <>
            <PageLayout
                title={
                    "Multilingual BPO | Outsourced Virtual Call Center Solutions | Offshore Customer Support Services India"
                }
            >
                <Container>
                    <div className="thankyou-page">
                        <img src={ThankyouImage} alt="Thank you" className="img-fluid" />
                        <div className="thankyou-content">
                            <h1>Thank You for Your Inquiry!</h1>
                            <p>We appreciate your interest in our services, products, and solutions. Our team will review your request and get back to you shortly.</p>
                        </div>
                        <button className="home-button" onClick={handleHomeClick}>
                            Home
                        </button>
                    </div>
                </Container>
            </PageLayout>
        </>
    );
}

export default Thankyou;
