import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PageLayout from '../../Components/PageLayout';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../pages/Policy/PrivacyPolicy.css';

const TermsCondition = () => {
    return (
        <>
            <PageLayout
                title={
                    "Multilingual BPO | Outsourced Virtual Call Center Solutions | Offshore Customer Support Services India"
                }
            >
                <div className='bg-gray'>
                    <Container>

                        <Row className="justify-content-center">
                            <Col md={10} className=' bg-white'>

                                <h1 className="my-4 privacy-policy-title">Terms & Conditions</h1>
                                <section className="privacy-policy-section mb-2">
                                    <h2 className='intro-heading'>Introduction</h2>
                                    <p>
                                        Welcome to Radical Minds Technologies Pvt. Ltd. ("we," "our," "us"). We are committed to protecting your privacy and ensuring that your personal information is handled securely and responsibly. This Terms & Conditions outlines how we collect, use, and safeguard your personal information when you visit our website <a href="http://www.radicalminds.in" target="_blank" rel="noopener noreferrer">www.radicalminds.in</a> (the “Website”). We adhere to national & international standards, including GDPR, to the best of our abilities to ensure your data is protected.
                                    </p>
                                </section>

                                <section className="privacy-policy-section">
                                    <h2 className='intro-heading'>Terms & Conditions</h2>
                                    <p>
                                        These Terms do not apply to your access to and use of the products and services which we market for subscription on our Websites (our “Services”). The practices and policies, including how we protect, collect, and use electronic data, text, messages, communications or other materials submitted to and stored within the Services by You (“Service Data”) are detailed in and governed by our Master Subscription Agreement, available here, or such other applicable agreement between you and any member of the Radical Minds relating to your access to and use of such Services (“Service Agreement”).
                                    </p>
                                    <ol>
                                        <li><strong>Changes to Terms</strong>.
                                            <p >These Terms, or any part thereof, may be modified by us, including the addition or removal of terms at any time, and such modifications, additions, or deletions will be effective immediately upon posting. Your use of the Websites after such posting shall be deemed to constitute acceptance by you of such modifications, additions, or deletions.</p>
                                        </li>
                                        <li><strong>Changes to Websites</strong>.
                                            <p>We may change or discontinue any aspect, service, or feature of the Websites at any time, including, but not limited to, content, availability, and equipment needed for access or use.</p>
                                        </li>
                                        <li><strong>Registration</strong>.
                                            <p>You may be given the opportunity to register via an online registration form or by participating in Interactive Areas (as defined below), such as forums and other community features, to create a user account ("Your Account") that may allow you to receive information from us and/or to participate in certain features on the Websites. We will use the information you provide in accordance with the Privacy Policy. By registering you represent and warrant that all information that you provide is current, complete, and accurate to the best of your knowledge. You agree to maintain and promptly update your information on the Websites so that it remains current, complete, and accurate. You are responsible for obtaining and maintaining all connectivity, computer software, hardware, and other equipment needed for access to and use of the Websites and all charges related to the same.</p>
                                        </li>
                                        <li><strong>User Content Guidelines</strong>.
                                            <p>The following terms apply to content submitted by you:</p>
                                            <ol type="a">
                                                <p>The Websites may contain comments sections, discussion forums, or other interactive features ("Interactive Areas") in which you may post or upload user-generated content, comments, video, photos, messages, other materials, or items (collectively, "User Content"). You are solely responsible for your use of any Interactive Areas and you use them at your own risk. Interactive Areas are available for individuals aged 13 years or older. By submitting User Content to an Interactive Area, you represent that you are 13 years of age or older and, if you are under the age of 18, you either are an emancipated minor or have obtained the legal consent of your parent or legal guardian to enter into these Terms, submit content, and participate on the Websites.</p>
                                                <p>You agree not to represent or suggest, directly or indirectly, the Radical Minds’ endorsement of User Content.</p>
                                                <p>You agree not to upload, post, or otherwise transmit any User Content, software, or other materials which contain a virus or other harmful or disruptive component.</p>
                                            </ol>
                                        </li>
                                    </ol>

                                </section>

                                <section className="privacy-policy-section">
                                    <h2 className='intro-heading'>Your Rights</h2>
                                    <p>Depending on your location, you may have the following rights regarding your personal information:</p>
                                    <ul>
                                        <li class="inline-list-item">
                                            <strong>Right to access</strong> - <p>You can request a copy of your personal information.</p>
                                        </li>
                                        <li class="inline-list-item">
                                            <strong>Right to rectification</strong> -  <p>You can request correction of inaccurate or incomplete data.</p>
                                        </li>
                                        <li class="inline-list-item">
                                           <strong>Right to erasure</strong> - <p>You can request deletion of your personal information under certain circumstances.</p>
                                        </li>
                                        <li class="inline-list-item">
                                            <strong>Right to restrict processing</strong> - <p>You can request to limit the processing of your data.</p>
                                        </li>
                                        <li class="inline-list-item">
                                           <strong>Right to data portability</strong> - <p>You can request transfer of your data to another organization.</p>
                                        </li>
                                        <li class="inline-list-item">
                                          <strong>Right to object</strong> - <p>You can object to the processing of your data in certain situations.</p>
                                        </li>
                                    </ul>


                                    <p>To exercise these rights, please contact us at <a href="mailto:info@radicalminds.in">info@radicalminds.in</a></p>
                                </section>

                                <section className="privacy-policy-section">
                                    <h2 className='intro-heading'>Contact Us</h2>
                                    <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
                                    <p>Email: <a href="mailto:info@radicalminds.in">info@radicalminds.in</a></p>
                                    <p>Last Updated: 10-July-2024</p>
                                    <p>By using our Website, you consent to our Privacy Policy and agree to its terms. We are committed to protecting your privacy and ensuring the security of your personal information. Thank you for trusting Radical Minds Technologies Pvt. Ltd.</p>
                                </section>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </PageLayout>
            <ToastContainer />
        </>
    );
}

export default TermsCondition;
