import React, { useState } from 'react';
import countryCode from "../Components/countryCode.json"; // Adjust the path as per your file structure

const MobileNumberInput = ({ formData, handleChange, isValidated, label, required }) => {
    const [selectedCountry, setSelectedCountry] = useState(countryCode[97]?.dial_code);

    const handleCountryChange = (e) => {
        const selectedValue = e.target.value;
        const selectedOption = countryCode.find(option => option.dial_code === selectedValue);
        setSelectedCountry(selectedOption.dial_code);
        handleChange({ target: { name: 'countryCode', value: selectedOption.dial_code } });
    };

    return (
        <>
            {label && (
                <label className="form-label">
                    {label}
                    {required ? <span className="text-danger ms-1">*</span> : null}
                </label>
            )}
            <div className="d-flex align-items-center">
                <div className="me-2" style={{ width: '120px' }}>
                    <select
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        className=""
                        style={{
                            borderRadius: '0.25rem',
                            height: 'calc(2em + 0.75rem + 2px)',
                            minHeight: 'calc(1em + 0.75rem + 2px)',
                            overflowY: 'auto',
                            maxHeight: '300px',
                            border: '1px solid rgb(206, 212, 218)'
                        }}
                    >
                        {countryCode.map((country, index) => (
                            <option key={index} value={country.dial_code} className='text' style={{ fontSize: 'small' }}>
                                {`${country.code} (${country.dial_code})`}
                            </option>
                        ))}
                    </select>
                </div>

                <input
                    onChange={handleChange}
                    id="mobile" 
                    name="mobile"
                    className="form-control"
                    type="number"
                    maxLength={10}
                    pattern="[0-9]{10}"
                    placeholder="Mobile"
                    style={{ border: '1px solid #ced4da', borderRadius: '0.25rem', height: 'calc(2em + 0.75rem + 2px)' }}
                    isValidated={isValidated}
                    defaultValue={formData.mobile ?? ""}
                />
            </div>
        </>
    );
};

export default MobileNumberInput;
